import Vue from 'vue'
import VueRouter from 'vue-router'
import RouterView from "../views/RouterView.vue";
import {i18n} from "@/main";

import Main from '../views/Main.vue'
import References from "../views/References";
import News from "../views/News";
import Contact from "../views/Contact";
import SiteNotice from "../views/SiteNotice";
import Admin from "../views/Admin";
import Commercial from "../views/Commercial";
import Imagefilm from "../views/filmpiraten/Imagefilm.vue";
import AboutUs from "@/views/AboutUs";
import Reference from "@/views/Reference";
import Fiction from "@/views/camcore/Fiction.vue";
import TShirts from "@/views/cancelcancer/TShirts.vue";
import Ambassadors from "@/views/cancelcancer/Ambassadors.vue";
import Association from "@/views/cancelcancer/Association.vue";
import Donate from "@/views/cancelcancer/Donate.vue";
import Membership from "@/views/cancelcancer/Membership.vue";
import Goals from "@/views/cancelcancer/Goals.vue";


Vue.use(VueRouter);

const navigatorLanguages = [
    {
        "key": "de",
        "codes": ["de", "de-*",],
    },
    {
        "key": "en",
        "codes": ["en", "en-*",],
    },
];

const allowedLanguages = ['de', 'en',];

const routes =
    [
        {
            path: "/:lang",
            component: RouterView,
            beforeEnter(to, from, next) {
                let lang = to.params.lang
                let target = lang

                lang = Vue.localStorage.get('language');

                if (!allowedLanguages.includes(lang)) {
                    for (let i = 0, j = navigatorLanguages.length; i < j; i++) {
                        let navigatorLanguage = navigator.language;
                        navigatorLanguage = navigatorLanguage.toLowerCase()

                        let wildcard = navigatorLanguage.substring(0, 3) + "*"

                        if (navigatorLanguages[i].codes.includes(navigatorLanguage)
                            || navigatorLanguages[i].codes.includes(wildcard)) {
                            lang = navigatorLanguages[i].key;
                            break;
                        }
                    }
                }

                if (!allowedLanguages.includes(lang)) {
                    lang = 'de';
                }

                if (i18n.locale !== lang) {
                    i18n.locale = lang;
                }

                if (!allowedLanguages.includes(target)) {
                    router.push('/' + lang + '/' + target);
                }

                return next();
            },
            children: [
                {
                    path: '',
                    name: 'main',
                    component: Main
                },

                {
                    path: 'werbung',
                    name: 'werbung',
                    component: Commercial
                },
                {
                    path: 'werbespot',
                    name: 'werbespot',
                    component: Commercial
                },
                {
                    path: 'commercial',
                    name: 'commercial',
                    component: Commercial
                },

                {
                    path: 'image',
                    name: 'image',
                    component: Imagefilm
                },
                {
                    path: 'imagefilm',
                    name: 'imagefilm',
                    component: Imagefilm
                },

                {
                    path: 'fiction',
                    name: 'fiction',
                    component: Fiction
                },
                {
                    path: 'fiktion',
                    name: 'fiktion',
                    component: Fiction
                },
                {
                    path: 'ouridea',
                    name: 'ouridea',
                    component: Fiction
                },
                {
                    path: 'unsereidee',
                    name: 'unsereidee',
                    component: Fiction
                },

                {
                    path: 'referenzen',
                    name: 'referenzen',
                    component: References,
                },
                {
                    path: 'references',
                    name: 'references',
                    component: References,
                },
                {
                    path: 'schatztruhe',
                    name: 'schatztruhe',
                    component: References,
                },

                {
                    path: 'references/:id',
                    name: 'reference',
                    component: Reference
                },
                {
                    path: 'referenzen/:id',
                    name: 'referenz',
                    component: Reference
                },
                {
                    path: 'schatztruhe/:id',
                    name: 'schatz',
                    component: Reference
                },

                {
                    path: 'news',
                    name: 'news',
                    component: News
                },

                {
                    path: 'contact',
                    name: 'contact',
                    component: Contact
                },
                {
                    path: 'kontakt',
                    name: 'kontakt',
                    component: Contact
                },
                {
                    path: 'anheuern',
                    name: 'anheuern',
                    component: Contact
                },

                {
                    path: 'ueberuns',
                    name: 'ueberuns',
                    component: AboutUs
                },
                {
                    path: 'aboutus',
                    name: 'aboutus',
                    component: AboutUs
                },
                {
                    path: 'besatzung',
                    name: 'besatzung',
                    component: AboutUs
                },

                {
                    path: 'impressum',
                    name: 'impressum',
                    component: SiteNotice
                },
                {
                    path: 'sitenotice',
                    name: 'sitenotice',
                    component: SiteNotice
                },

                {
                    path: 'admin',
                    name: 'admin',
                    component: Admin
                },

                {
                    path: 't-shirts',
                    name: 'tshirts',
                    component: TShirts
                },
                {
                    path: 'botschafter:innen',
                    name: 'ambassadors',
                    component: Ambassadors
                },
                {
                    path: 'derverein',
                    name: 'association',
                    component: Association
                },
                {
                    path: 'unsereziele',
                    name: 'goals',
                    component: Goals,
                },
                {
                    path: 'spenden',
                    name: 'donate',
                    component: Donate
                },
                {
                    path: 'mitgliedschaft',
                    name: 'membership',
                    component: Membership
                },


                {
                    path: 'work/balitherme',
                    redirect: "referenzen/balitherme"
                },
                {
                    path: 'work',
                    redirect: "referenzen"
                },
                {
                    path: 'services',
                    redirect: "werbespot"
                },
                {
                    path: 'corporatevideo',
                    redirect: "image"
                },
                {
                    path: 'work/phantomschmerz',
                    redirect: "referenzen/phantomschmerz"
                },
                {
                    path: "booking",
                    redirect: "consulting"
                },
                {
                    path: 'consulting',
                    redirect: "kontakt"
                },
            ]
        },
        {
            path: "*",
            redirect: "/de/"
        },
        {
            path: "/*/*",
            redirect: "/de/"
        },
        {
            path: "/en/*",
            redirect: "/de/"
        },
    ];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
});

export default router;
