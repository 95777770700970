<template>
    <div class="contact-formular">

        <div uk-grid>
            <div v-for="(inputField, key) in textFields" :key="inputField.name" :class="inputField.width">
                <TextArea v-if="inputField.type==='textarea'"

                          v-model="inputField.value"
                          :autofocus="inputField.autofocus"
                          :field-key="key"
                          :icon="inputField.icon"
                          :label="inputField.label"
                          :name="inputField.name"
                          :required="inputField.required"
                          :rules="inputField.rules"
                          :type="inputField.type"
                          :validate="trigger"
                          @setValid="setValid"
                />
                <TextField v-else
                           v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="inputField.onEnter"
                           :required="inputField.required"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
        </div>

        <div class="uk-grid" uk-grid>
            <div>
                <div class="input-headline">{{ $t('preferredContactOption') }}</div>

                <div uk-grid>
                    <div>
                        <label class="cursor-pointer">
                            <input v-model="preferred" class="uk-radio" name="contact-option" type="radio"
                                   value="email">
                            <span class="preferred-item">{{ $t('email') }}</span>
                        </label>
                    </div>
                    <div>
                        <label v-if="isTelValid" class="cursor-pointer">
                            <input v-model="preferred" class="uk-radio" name="contact-option"
                                   type="radio" value="telephone">
                            <span class="preferred-item">{{ $t('telephone') }}</span>
                        </label>

                        <label v-else :uk-tooltip="'title:'+$t('telephoneSelectDisabledMessage')"
                               class="cursor-notallowed">
                            <input v-model="preferred" class="uk-radio cursor-notallowed" disabled="true"
                                   name="contact-option"
                                   type="radio" value="telephone">
                            <span class="preferred-item">{{ $t('telephone') }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="uk-width-1-1">
                <Checkbox v-model="confirmTerms" :validate="trigger"
                          label="acceptDataPrivacyMessage" name="terms" rules="required" @setValid="setTermsValid"/>
            </div>

        </div>


        <div class="uk-margin-medium-top"/>
        <div class="uk-text-center">

            <div v-if="loading">
                <div class="loading" uk-spinner/>
            </div>

            <div v-else-if="requestStatus === 201" class="uk-text-center strong-message">
                <ActionHeadlineMessage
                    :headline="$t('messageSent')"
                    :message="$t('sentMessage')"/>
            </div>
            <div v-else-if="requestStatus === 429" class="uk-text-center strong-message">
                <ActionHeadlineMessage
                    :headline="$t('tooManyRequests')"
                    :message="$t('sentMessageTooManyRequests')"/>

                <div class="uk-margin-medium-top"/>
                <span v-on:click="contact()">
                    <Button :text="$t('retrySend')" is-large is-min-width mode="primary"/>
                </span>
            </div>
            <div v-else-if="requestStatus === 500" class="uk-text-center strong-message">
                <ActionHeadlineMessage
                    :headline="$t('error')"
                    :message="$t('serverError')"/>

                <div class="uk-margin-medium-top"/>
                <span v-on:click="contact()">
                    <Button :text="$t('retrySend')" is-large is-min-width mode="primary"/>
                </span>
            </div>
            <div v-else-if="requestStatus > 0" class="uk-text-center strong-message">
                <ActionHeadlineMessage
                    :headline="$t('error')"
                    :message="$t('clientError')"/>

                <div class="uk-margin-medium-top"/>
                <span v-on:click="reload()">
                    <Button :text="$t('reload')" is-large is-min-width mode="primary"/>
                </span>
            </div>

            <div v-else>
                <span v-on:click="contact()">
                    <Button :text="$t('send')" is-large is-min-width mode="primary"/>
                </span>
            </div>

            <div v-if="acquisitionUuid"
                 class="uk-animation-slide-bottom-small">

                <div class="uk-margin-medium-top"/>
                <h3 class="white">{{ $t('howDidYouFindUs') }}</h3>

                <div
                    class="uk-grid-small uk-child-width-1-3@s uk-child-width-1-1 uk-flex-center uk-grid-match uk-flex uk-flex-middle"
                    uk-grid>
                    <div v-for="option in acquisitionOptions" :key="option.name">
                        <span v-on:click="feedback= option.name">
                            <CardSelection
                                :icon="option.icon"
                                :name="$t(option.name)"
                                :selected="(feedback === option.name)"/>
                        </span>
                    </div>
                </div>

                <div class="uk-margin-medium-bottom"/>
                <span v-if="loadingFeedback">
                    <div
                        class="uk-text-center uk-animation-slide-bottom-small feedback-thanks">
                        <div uk-spinner/>
                    </div>
                </span>
                <span v-else-if="!requestStatusFeedback">

                    <span v-if="feedback"
                          v-on:click="acquisition()">
                        <Button :text="$t('sendFeedback')" is-large is-min-width mode="primary"/>
                    </span>


                   <Button v-else
                           :text="$t('sendFeedback')"
                           :uk-tooltip="'title:'+$t('pleaseSelectAnOption')" disabled is-large is-min-width
                           mode="disabled"/>
                </span>
                <span v-else>
                    <div
                        class="uk-text-center uk-animation-slide-bottom-small feedback-thanks">
                        {{ $t('feedbackSentMessage') }}
                    </div>
                </span>


                <hr class="uk-margin-medium-top uk-margin-medium-bottom"/>
                <span v-on:click="prepareForNewMessage()">
                    <Button :text="$t('send.another.message')" is-large is-min-width mode="primary"/>
                </span>

            </div>


        </div>

    </div>
</template>


<script>

import {Validator} from 'vee-validate';
import PhoneNumber from 'awesome-phonenumber'
import axios from "axios";
import ActionHeadlineMessage from "@/components/ActionHeadlineMessage";
import Button from "@/components/Button";
import CardSelection from "@/components/CardSelection";
import TextField from "@/components/TextField";
import TextArea from "@/components/TextArea";
import Checkbox from "@/components/Checkbox";
import UIkit from "uikit";

export default {
    name: 'MembershipFormular',
    components: {Checkbox, TextArea, TextField, CardSelection, Button, ActionHeadlineMessage},
    props: {
        selected: Boolean,
        name: String,
        icon: String,
    },
    data() {
        return {
            feedback: null,
            loadingFeedback: false,
            requestStatusFeedback: null,

            acquisitionOptions: [
                {name: "recommendation", icon: "users"},
                {name: "socialMedia", icon: "hashtag"},
                {name: "searchEngine", icon: "search"},
            ],

            trigger: 0,
            textFields: [
                {
                    autofocus: false,

                    value: "",
                    valid: false,
                    name: "name",
                    label: "name",
                    rules: "required|alpha_spaces",
                    icon: "user",
                    width: "uk-width-1-2@s uk-width-1-1",
                    required: true,
                },
                {
                    value: "",
                    valid: true,

                    name: "company",
                    label: "company",
                    rules: "",
                    icon: "home",
                    width: "uk-width-1-2@s uk-width-1-1",
                },
                {
                    value: "",
                    valid: false,

                    name: "emailAddress",
                    label: "emailAddress",
                    rules: "required|email",
                    icon: "mail",
                    width: "uk-width-1-2@s uk-width-1-1",
                    required: true,
                },
                {
                    value: "",
                    valid: true,

                    name: "telephone",
                    label: "telephone",
                    rules: "phone",
                    icon: "receiver",
                    width: "uk-width-1-2@s uk-width-1-1",
                },
                {
                    value: "",
                    valid: false,

                    name: "message",
                    label: "message",
                    rules: "required|min:1|max:4000",

                    type: "textarea",
                    width: "uk-width-1-1",
                    required: true,
                },
            ],

            preferred: "email",
            privacy: false,
            confirmTerms: false,

            loading: null,
            requestStatus: null,
            acquisitionUuid: 0,
        };
    },
    created() {
        let self = this;
        Validator.extend('phone', {
            message(fieldName) {
                return `${fieldName} is not a valid phone number`;
            },
            validate(value) {
                return new Promise(resolve => {
                    let phone = new PhoneNumber(value, self.$i18n.locale);
                    resolve({valid: phone.isValid()})
                });
            }
        });
    },
    computed: {
        isTelValid() {
            let telValue = this.textFields[3].value;

            if (telValue.length <= 0) {
                this.resetPreferred();
                return false;
            }

            if (this.errors.first("telephone")) {
                this.resetPreferred();
                return false;
            }

            return true;
        },
    },
    methods: {
        reload() {
            location.reload();
        },
        setTermsValid(valid) {
            this.privacy = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid;
        },
        prepareForNewMessage() {
            /*
            for (let i = 0, j = this.textFields.length; i < j; i++) {
                this.textFields[i].value = "";
            }
             */

            // this.textFields[0].valid = false;
            // this.textFields[2].valid = false;

            this.textFields[4].value = "";
            this.textFields[4].valid = false;

            this.preferred = "email";
            // this.confirmTerms = false;
            // this.privacy = false;
            this.requestStatus = null;
            this.acquisitionUuid = 0;
            this.requestStatusFeedback = null;
            this.feedback = null;

            UIkit.scroll("#contact-form", {offset: 30}).scrollTo("#contact-form");
        },
        acquisition() {
            this.loadingFeedback = true;
            this.requestStatusFeedback = null;

            let http = axios.create();
            http.post(this.getBaseUrl + '/api/acquisitions', {
                uuid: this.acquisitionUuid,
                feedback: this.feedback,

            }).then(response => {
                this.requestStatusFeedback = response.status;
                this.loadingFeedback = false;

            }).catch(e => {
                this.requestStatusFeedback = 500;
                if (e && e.response) {
                    this.requestStatusFeedback = e.response.status;
                }

                this.loadingFeedback = false;
            });
        },
        resetPreferred() {
            this.preferred = "email";
        },
        contact() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            if (!this.privacy) {
                return;
            }

            this.loading = true;
            this.requestStatus = null;

            let http = axios.create();

            http.post(this.getBaseUrl + '/api/contacts', {
                name: this.textFields[0].value,
                company: this.textFields[1].value,
                email: this.textFields[2].value,
                telephone: this.textFields[3].value,
                message: this.textFields[4].value,
                preferred: this.preferred,
                language: this.$i18n.locale

            }).then(response => {
                this.requestStatus = response.status;
                this.acquisitionUuid = response.data.acquisition_code;
                this.loading = false;

            }).catch(e => {
                this.requestStatus = 500;
                if (e && e.response) {
                    this.requestStatus = e.response.status;
                }

                this.loading = false;
            });
        },
    }
}

</script>


<style lang="scss" scoped>

.loading {
    height: 50px !important;
}

.feedback-thanks {
    font-size: 18px;
    height: 50px;
}

.uk-radio:checked {

}

</style>
