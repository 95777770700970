<template>
    <div class="contact uk-animation-fade">
        <Seo :title="$t('aboutUs')" canonical="/contact"/>

        <VideoModal v-if="getConfig.bts"
                    :modal-id="modalId"
                    :video-src="getConfig.bts"/>

        <div class="uk-grid-collapse uk-grid-match" uk-grid>
            <div class="uk-width-1-1 video-struct first-video">
                <div class="uk-cover-container uk-height-1-1 uk-animation-fade">
                    <video id="video-aboutus" class="video-main"
                           muted
                           oncontextmenu="return false;" playsinline uk-cover="autoplay: inview">
                        <source :src="require('../assets/'+getMode+'/videos/bts.mp4')" type="video/mp4">
                    </video>
                    <VideoLoop id="video-aboutus"/>

                    <div class="uk-position-center uk-text-center">
                        <SiteHeadline is-main
                                      :headline="$t('aboutUs')"
                                      :message="$t('aboutus.title')"
                                      has-button>


                            <a href="#bts">
                                <Button :text="$t('watch.bts')" is-large is-min-width mode="default"
                                        :uk-toggle="'target: #'+modalId"/>
                            </a>


                            <div v-if="false"
                                 class="uk-margin-medium-top site-width single uk-text-center strong-message"
                                 v-html="$t('aboutus.description')"/>


                            <FeatureItem
                                class="site-width single uk-text-left uk-margin-medium-top"
                                :message="$t('aboutus.description')"/>

                            <Button v-if="false"
                                    :text="$t('watch')" is-large is-min-width mode="default"
                                    uk-toggle="target: #modal-showreel"/>

                        </SiteHeadline>

                    </div>
                    <div class="uk-position-bottom uk-text-center uk-margin-bottom next-item"
                         uk-scrollspy="cls: uk-animation-slide-top-small; delay: 1000;">
                        <a class="uk-link-muted uk-link-white" href="#info"
                           :uk-scroll="'offset: '+ (getMode === 'camcore' ? 65 : 30)">
                            <span uk-icon="icon: chevron-down; ratio: 2"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <VideoModal modal-id="modal-behindthescenes"
                    video-src="https://player.vimeo.com/external/509178207.hd.mp4?s=81d379c23777013b90417b723b0fda7d7785fdee&profile_id=175"/>

        <div class="site-width">
            <span id="info"/>

            <div v-if="false" class="uk-child-width-1-3 uk-flex-center uk-flex" uk-grid>
                <div>
                    <VideoHeadlineItem
                        id="bts"
                        key="0"
                        data-category="bts"
                        itemKey="0"
                        shortname="bts"
                        :video="getConfig.bts"
                        :videoPreview="require('../assets/'+getMode+'/videos/bts.mp4')"
                        class="item-struct"
                        is-max
                    />
                </div>
            </div>

            <div class="uk-margin-medium-top"/>
            <Steps :steps="getConfig.aboutUsSteps"
                   :width="getConfig.aboutUsSteps.length === 2 ? 'uk-child-width-1-2@s uk-child-width-1-1' : 'uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1'"
                   disable-steps without-headline/>


            <div class="uk-margin-large-top"/>
            <Team :headline="$t('team')"
                  :message="$t('whoWeAre')"
                  :team="getConfig.team"/>

            <div class="uk-margin-large-top"/>
            <SubTeam :team="getConfig.subteam"/>

            <div class="last-button-distance"/>
            <LastHeadlineButton class="site-width single"
                                :buttonMessage="$t('contact')" :headline="$t('createYourOwnStoryTogether')"
                                :route="'contact'"
                                buttonClass="success"/>

            <div class="last-button-distance"/>

        </div>
    </div>
</template>


<script>

import Seo from "../components/Seo";
import Team from "@/components/Team";
import LastHeadlineButton from "@/components/LastHeadlineButton";
import SiteHeadline from "@/components/SiteHeadline";
import Button from "@/components/Button";
import VideoModal from "@/components/VideoModal";
import VideoLoop from "@/components/VideoLoop";
import SubTeam from "@/components/SubTeam.vue";
import FeatureItem from "@/components/FeatureItem.vue";
import Steps from "@/components/Steps.vue";
import VideoHeadlineItem from "@/components/VideoHeadlineItem.vue";
import UIkit from "uikit";

export default {
    name: 'AboutUs',
    components: {
        VideoHeadlineItem,
        Steps,
        FeatureItem,
        SubTeam,
        VideoLoop,
        VideoModal,
        Button,
        SiteHeadline,
        LastHeadlineButton,
        Team,
        Seo,
    },
    mounted() {
        let hash = this.$route.hash;

        if (hash === "#bts") {
            let self = this;
            UIkit.modal("#" + this.modalId).show();

            const modalElement = document.getElementById(this.modalId);
            modalElement.addEventListener("beforeshow", function () {
                self.interval = setInterval(function () {
                    self.stopVideos();
                }, 100);
            });

            modalElement.addEventListener("beforehide", function () {
                clearInterval(self.interval);
            });
        }
    },
    data() {
        return {
            modalId: "modal-bts",

            features: [
                {
                    "title": "history",
                    "icon": "history",
                    "message": "historyMessage"
                },
                {
                    "title": "vision",
                    "icon": "star",
                    "message": "visionMessage"
                },
            ],

            featuresFP: [
                {
                    "title": "vision",
                    "message": "visionMessage"
                },
                {
                    "title": "vision",
                    "message": "visionMessage"
                },
                {
                    "title": "vision",
                    "message": "visionMessage"
                },
            ],
        };
    },
}

</script>


<style lang="scss" scoped>

.text-struct {
    font-size: 18px !important;
}

.video-main {
    opacity: 0.65;
}

</style>
