<template>
    <div class="site-width uk-animation-fade single">

        <Seo :canonical="'/'+$t('membership').toLowerCase()" :title="$t('membership')"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main :message="$t('membership.message')"
                      :headline="$t('membership')"
                      has-button>
            <div class="headline-message-distance"/>

            <FeatureItem
                class="uk-text-left"
                message="Mitglied Info Text"/>
        </SiteHeadline>
        <div class="headline-message-distance"/>

        <div class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding site-width single">
        <MembershipFormular/>
        </div>


        <div class="section-distance"/>




        <div class="last-button-distance"/>
    </div>
</template>


<script>

import Seo from "../../components/Seo.vue";
import FeatureItem from "@/components/FeatureItem.vue";
import SiteHeadline from "@/components/SiteHeadline.vue";
import MembershipFormular from "@/views/cancelcancer/MembershipFormular.vue";


export default {
    name: 'Membership',
    components: {
        MembershipFormular,
        SiteHeadline,
        FeatureItem,
        Seo,
    },
    created() {
        if (this.getMode !== "cancelcancer") {
            this.routeTo("")
        }
    },
}

</script>


<style lang="scss" scoped>

</style>
