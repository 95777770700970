<template>
    <div class="email-contact">

        <SiteHeadline :headline="$t('wire')"
                      :message="$t('wire.message')"/>
        <div class="headline-item-distance"/>
        <div
            class="uk-card uk-card-alternative uk-dark-card-hovered uk-padding uk-text-center site-width single">

            <Translate class="description" translation-key="scan.with.banking.app"/>

            <div class="uk-margin-top"/>
            <div ref="qrcode"/>


            <div class="uk-margin-medium-top"/>
            <Button is-large is-min-width mode="primary" has-action :action="downloadCode">
                <Translate translation-key="download.qr.code"/>
            </Button>

            <div class="uk-margin-medium-top"/>
            <hr class="uk-hr "/>
            <div class="uk-margin-medium-top"/>


            <div v-for="paymentDetail in paymentDetails"
                 :key="paymentDetail.description"
                 class="uk-text-center  cursor-pointer">
                <div
                    v-on:click="$copyText(paymentDetail.value)"
                    :uk-tooltip="'title:'+$t('copyToClipboard')">

                    <div class="payment-name">
                        {{ paymentDetail.description }}
                    </div>
                    <div class="location-address">



                     {{ paymentDetail.value }}
                    <span v-if="paymentDetail.additional">
                                                                ({{ paymentDetail.additional }})
                                                            </span>
                    <span class="uk-margin-small-left" v-if="false"
                          uk-icon="icon: copy-clipboard; ratio: 1"/>
                    </div>
                </div>
            </div>


            <div class="uk-margin-medium-top"/>
            <Button is-large is-min-width mode="primary" has-action :action="copyBankData">
                <Translate translation-key="copyToClipboard"/>
            </Button>
        </div>

    </div>
</template>


<script>

import SiteHeadline from "./SiteHeadline";
import Button from "@/components/Button";
import QRCode from "easyqrcodejs";
import Translate from "@/components/Translate.vue";

export default {
    name: 'Wire',
    components: {Translate, Button, SiteHeadline},
    mounted() {
        const options = {
            text: this.bankingData,
        }

        this.qrCode = new QRCode(this.$refs.qrcode, options);

    },
    methods: {
        downloadCode() {
            this.qrCode.download("CancelCancer Überweisung QR-Code");
        },
        copyBankData() {
            let text = "";

            for (let i = 0, j = this.paymentDetails.length; i < j; i++) {
                text += this.paymentDetails[i].description + ": " + this.paymentDetails[i].value + " "
            }

            this.$copyText(text.trim())
        }
    },
    data() {
        return {
            bankingData: "BCD\n" +
                "002\n" +
                "1\n" +
                "SCT\n" +
                "BRLADE21BRS\n" +
                "Cancel Cancer e.V.\n" +
                "DE94292500001030117403",

            qrCode: null,

            paymentDetails: [
                {
                    description: "Empfänger",
                    value: "Cancel Cancer e.V."
                },
                {
                    description: "IBAN",
                    value: "DE94 2925 0000 1030 1174 03"
                },
                {
                    description: "BIC",
                    value: "BRLADE21BRS",
                    additional: "Bremerhaven"
                }
            ],
        };
    },
}

</script>


<style lang="scss" scoped>

.payment-name{
font-weight: 500;
    font-size: 16px;
}

.location-address {
    color: #f2f2ff;
    opacity: 0.9;
    line-height: 1.4;
    letter-spacing: .007em;
    font-size: 20px;
    margin-bottom: 18px;

    &.name {
        font-size: 22px;
        font-weight: 500;
    }
}

.available-info {
    margin-top: 15px;
    font-size: 16px;
}

.available-number {
    margin-bottom: 15px;
}

.description {
    font-size: 18px;
    font-weight: 500;
}

</style>
