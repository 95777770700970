<template>
    <div class=" uk-animation-fade">

        <Seo :canonical="'/'+$t('references').toLowerCase()" :title="$t('references')"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main :headline="$t('references')"
                      :message="$t('mainMessage')"/>


        <LoadingStruct :is-loading="loading" is-animation-disabled>
            <div class="headline-message-distance"/>
            <div class="site-width" uk-filter="target: .js-filter">
                <ul :class="'uk-subnav uk-subnav-pill uk-flex-center uk-margin-medium-bottom uk-grid-small' + (getMode==='filmpiraten'?' ':'')"
                    uk-grid>
                    <li v-if="getMode !== 'camcore'"
                        :class="(filter ? '': 'uk-active') +  (getMode==='filmpiraten'?' ':'')"
                        :uk-filter-control="'[data-selection=\''+'1'+'\']'" v-on:click="setHashtag('')">
                        <a :class="(getMode==='filmpiraten'?' secondary':'')" href="#">{{ $t('selection') }}</a>
                    </li>
                    <li v-for="category in getCategories" :key="category"
                        :class="(filter && filter === category ? 'uk-active': '') + (getMode==='filmpiraten'?' ':'')"
                        :uk-filter-control="'[data-category=\''+category+'\']'"
                        v-on:click="setHashtag(category)"
                    >
                        <a :class="(getMode==='filmpiraten'?' secondary':'')" :href="'#'+category"
                           v-html="$t(category)"/>
                    </li>
                </ul>

                <ul class="js-filter uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center"
                    uk-grid>
                    <VideoHeadlineItem v-for="(product, key) in references" :id="product.id+''" :key="key"
                                       :data-category="product.category"
                                       :data-selection="product.favorite"

                                       :headline="$t(product.category)" :isCampaign="product.video_link === ''"
                                       :itemKey="key"
                                       :logo="product.logo ?getBaseUrl+'/api/storage/app/'+product.logo : ''"
                                       :message="product.name"
                                       :caption="product.caption"
                                       :route="product.video_link === '' ? '/'+$t('references').toLowerCase()+'/'+product.shortname : ''"
                                       :shortname="product.shortname"
                                       :video="product.video_link"
                                       :videoPreview="getBaseUrl+'/api/storage/app/'+product.preview_video"
                                       class="item-struct"
                                       is-max
                    />
                </ul>
            </div>

            <div class="section-distance-new"/>
            <NumberReferences v-if="false"/>


            <!-- SHOWREEL -->
            <span id="showreel">
            <div class="uk-grid-collapse video-struct uk-grid-match" uk-grid>
                <div class="uk-width-1-1 uk-height-viewport">
                    <div class="uk-cover-container uk-height-1-1 uk-animation-fade">
                        <video id="video-showreel" class="video-showreel"
                               muted
                               oncontextmenu="return false;" playsinline uk-cover="autoplay: inview">
                            <source :src="require('../assets/'+getMode+'/videos/showreel.mp4')" type="video/mp4">
                        </video>
                        <VideoLoop id="video-showreel"/>

                        <div class="uk-position-center uk-text-center">
                            <SiteHeadline
                                v-if="getMode === 'camcore'"
                                :headline="$t('showreel')"
                                :message="$t('showreelMessage')"
                                has-button
                                uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 200">
                                <a href="#showreel">
                                <Button :text="$t('watch')" is-large is-min-width mode="default"
                                        uk-toggle="target: #modal-showreel"/>
                                    </a>
                            </SiteHeadline>


                            <SiteHeadline
                                v-else
                                :message="$t('createYourOwnStory')"
                                has-button
                                uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 200">
                                <LinkObject to="contact">
                                    <Button :text="$t('contact')" is-large is-min-width mode="success"/>
                                </LinkObject>

                                <div class="uk-margin-small-top">
                                    {{ $t('freeOffer') }}
                                </div>
                            </SiteHeadline>

                        </div>
                    </div>
                </div>
            </div>
        </span>

            <VideoModal modal-id="modal-showreel"
                        :video-src="getConfig.showreel"/>
            <!-- /SHOWREEL -->

            <span v-if="getMode === 'camcore'">
            <div class="last-button-distance"/>
            <LastHeadlineButton :buttonMessage="$t('contact')" :headline="$t('createYourOwnStory')" :route="'contact'"
                                :subMessage="$t('freeOffer')"
                                buttonClass="success"/>

                </span>
            <div class="last-button-distance"/>

        </LoadingStruct>

    </div>
</template>


<script>

import SiteHeadline from "../components/SiteHeadline";
import Seo from "../components/Seo";
import LastHeadlineButton from "../components/LastHeadlineButton";
import VideoHeadlineItem from "../components/VideoHeadlineItem";
import axios from "axios";
import NumberReferences from "@/components/NumberReferences";
import LoadingStruct from "@/components/LoadingStruct";
import VideoModal from "@/components/VideoModal.vue";
import VideoLoop from "@/components/VideoLoop.vue";
import Button from "@/components/Button.vue";
import LinkObject from "@/components/LinkObject.vue";

export default {
    name: 'Services',
    components: {
        LinkObject,
        Button,
        VideoLoop,
        VideoModal,
        LoadingStruct,
        NumberReferences,
        VideoHeadlineItem,
        LastHeadlineButton,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            filter: null,
            loading: true,

            references: [],
            limit: 9,
        };
    },
    created() {
        if (this.getMode === 'camcore') {
            this.filter = 'fiction';
        }

        this.getReferences();

        let hash = this.$route.hash;
        if (hash) {
            this.filter = hash.substring(1);
        }
    },
    methods: {
        showAll() {
            let itemsLength = this.references.length;
            let limit = this.limit + 9;

            if (limit > itemsLength) {
                limit = itemsLength;
            }

            this.limit = limit;
        },
        setHashtag(tab) {
            history.pushState({}, null, "#" + tab);
        },
        getReferences() {
            let http = axios.create();

            this.loading = true;
            http.get(this.getBaseUrl + '/api/references').then(response => {
                this.references = response.data;
                this.loading = false;

            }).catch(() => {
                this.loading = false;

            });
        },
    },
    computed: {
        computedReferences() {
            let items = this.references;
            let limit = this.limit;

            if (!items) {
                return [];
            }

            return Object.entries(items).slice(0, limit).map(entry => entry[1]);
        },
        getCategories() {
            let categories = [];
            for (let i = 0, j = this.references.length; i < j; i++) {
                categories.push(this.references[i].category);
            }
            let groupedCategories = [...new Set(categories)];

            let filteredCategories = [];

            let targetCategories = ['fiction', 'commercial', 'image', 'recruiting', 'behindTheScenes'];

            if (this.getMode === 'filmpiraten') {
                targetCategories = ['commercial', 'image', 'fiction', 'recruiting', 'behindTheScenes'];
            }

            for (let i = 0, j = targetCategories.length; i < j; i++) {
                if (groupedCategories.includes(targetCategories[i])) {
                    filteredCategories.push(targetCategories[i]);
                }
            }

            return filteredCategories;
        }
    }
}

</script>


<style lang="scss" scoped>

.uk-subnav-pill > .uk-active > a {
    color: #fff !important;
    background-color: $info-color-hover !important;
    border: 1px solid #e5e5e5 !important;

    &.secondary {
        background-color: $fp-color !important;
    }
}

</style>
