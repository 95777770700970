<template>
    <div class="new-section-struct site-width single">

        <SiteHeadline :headline="headline" :message="message"
                      :switch-headline="switchHeadline" class="uk-margin-bottom"/>

        <div
            :class="'section-headline-distance uk-grid-large uk-grid-match uk-child-width-expand@xl uk-child-width-1-1 uk-flex uk-flex-center' + (features.length===1 ? ' single':' uk-child-width-1-2@m')"
            uk-grid>
            <div v-for="feature in features" :key="feature.title">
                <FeatureItem :headline="$t(feature.title)"
                             :icon="feature.icon"
                             :message="$t(feature.message)"/>

                <div class="uk-margin-medium-bottom"/>
            </div>
        </div>

        <div :class="'block-button-distance uk-text-center uk-width-1-1'+(features.length===1 ? ' single':'')">
            <router-link :to="'/'+$i18n.locale+'/'+$t('contact').toLowerCase()">
                <Button :text="$t('contact')" is-large is-min-width mode="success"/>
            </router-link>
            <div class="uk-margin-small-top">
                {{ $t('freeOffer') }}
            </div>
        </div>


    </div>
</template>


<script>

import FeatureItem from "./FeatureItem";
import SiteHeadline from "./SiteHeadline";
import Button from "@/components/Button";

export default {
    name: 'FeaturesList',
    components: {Button, SiteHeadline, FeatureItem},
    props: {
        features: Array,
        headline: String,
        message: String,
        switchHeadline: Boolean,
    },
}

</script>


<style lang="scss" scoped>

.features-list {
    padding: 80px 40px 80px 40px !important;
}

.video-showreel {
    opacity: 0.6;
}

.section-headline-distance {
    margin-top: 80px;
}

.block-button-distance {
    margin-top: 50px;

    &.additional-distance {
        margin-top: 100px;
    }
}

.block-headline-distance {
    margin-bottom: 80px;
}

.text-button-distance {
    margin-top: 100px;
}

.title-distance {
    margin-top: 120px;
}

.view-distance {
    height: 12px;
}

.uk-card-dark {
    background: $alternative-color;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-width-very-small {
    width: 70px;
}

#features {
    padding-top: -70px;
}

</style>
