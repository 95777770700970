<template>

    <LinkToggleObject :id="shortname" :target="'#'+modalId" :to="route" v-on:click="stopVideos()">
        <a :href="isCampaign ? '/'+$i18n.locale+route : '#'+shortname">
            <div class="uk-card uk-card-alternative uk-height-1-1">
                <div
                    :class="'video-struct-item '+(isMax ? 'uk-width-1-1' : 'uk-width-2-3') + (isFlexLast ? ' uk-flex-last' :'')">

                    <div class="uk-cover-container uk-animation-fade">
                        <video :id="'video-desktop-'+itemKey" class="uk-width-1-1 video-item"
                               muted
                               oncontextmenu="return false;" playsinline uk-video="autoplay: inview">
                            <source :src="videoPreview"
                                    type="video/mp4">
                        </video>
                        <VideoLoop :id="'video-desktop-'+itemKey"/>

                        <div class="uk-hidden-notouch uk-position-center uk-text-center">
                            <span v-if="isCampaign" uk-icon="icon: album; ratio: 3"></span>
                            <span v-else uk-icon="icon: play; ratio: 3"></span>
                        </div>
                        <div
                            :uk-tooltip="'title:'+$t((customTooltip ? customTooltip : (isCampaign ? 'open' : 'watch')))"
                            class="uk-hidden-touch uk-position-center uk-text-center">
                            <span v-if="isCampaign" uk-icon="icon: album; ratio: 3"></span>
                            <span v-else uk-icon="icon: play; ratio: 3"></span>
                        </div>
                    </div>
                </div>

                <div v-if="message || headline" class="uk-text-center uk-card-body uk-padding-mid">
                    <div v-if="!logo" class="message" v-html="message"></div>
                    <img v-else :alt="message" :src="logo"
                         class="logo" uk-img/>

                    <div v-if="caption" class="caption">
                        {{ caption }}
                    </div>

                    <div v-if="headline && headline.length>0" class="headline" v-html="headline"></div>


                </div>
            </div>
        </a>

        <VideoModal v-if="video" :modal-id="modalId" :video-src="video"/>

    </LinkToggleObject>

</template>


<script>

import LinkToggleObject from "@/components/LinkToggleObject";
import VideoModal from "@/components/VideoModal";
import UIkit from "uikit";
import VideoLoop from "@/components/VideoLoop";

export default {
    name: 'VideoHeadlineItem',
    components: {VideoLoop, VideoModal, LinkToggleObject},
    props: {
        id: String,
        route: String,
        caption: String,
        itemKey: Number,
        videoPreview: String,
        videoSource: String,
        headline: String,
        message: String,
        logo: String,
        isCampaign: Boolean,
        customTooltip: String,
        isMax: Boolean,
        isFlexLast: Boolean,
        video: String,
        shortname: String,
        currentVideo: null,
    },
    data() {
        return {
            modalId: 'modal-video-' + this.shortname + '-' + this.id,
            interval: 0,
        };
    },
    mounted() {
        let hash = this.$route.hash;

        if (hash === "#" + this.shortname) {
            let self = this;
            setTimeout(function () {
                UIkit.scroll("#" + self.shortname).scrollTo("#" + self.shortname);
            }, 50);

            UIkit.modal("#" + this.modalId).show();

            const modalElement = document.getElementById(this.modalId);
            modalElement.addEventListener("beforeshow", function () {
                self.interval = setInterval(function () {
                    self.stopVideos();
                }, 100);
            });

            modalElement.addEventListener("beforehide", function () {
                clearInterval(self.interval);
            });
        }
    },
}

</script>


<style lang="scss" scoped>

.video-item {
    opacity: 0.9;
    border-radius-topleft: $border-radius;
    border-radius-topright: $border-radius;
}

.uk-card {
    border-radius: $border-radius;
}

.headline {
    color: #f2f2ff;
    opacity: 0.5;
    line-height: 1.14286;
    letter-spacing: .007em;
    text-transform: capitalize;
    margin-top: 10px !important;
    font-size: 21px;
}

.message {
    font-weight: 600;
    line-height: 1.16667;
    letter-spacing: .009em;
    color: #f2f2ff;
    font-size: 30px;
}

@media (max-width: 432px) {
    .headline {
        font-size: 18px;
    }

    .message {
        font-size: 28px;
    }
}

.video-container-struct {
    height: calc(80vh - 80px - 1px);
}

.logo {
    max-width: 220px;
    max-height: 100px;
    width: initial;
    height: initial;
    margin-top: 8px;
}

.caption {
    margin-top: 6px;
    font-size: 22px;
}

.uk-padding-mid {
    padding: 20px;
}

</style>
