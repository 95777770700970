<template>
    <div class="site-width uk-animation-fade">

        <Seo :description="$t('fiction.description')" :title="$t('fiction.title')" canonical="/fiction"/>

        <div class="menu-headline-distance"/>
        <SiteHeadline is-main :headline="$t('fiction.title')" :message="$t('fiction.headline')"/>
        <div class="headline-message-distance"/>

        <HighlightVideoText :service-items="serviceItems" toggle-sides/>

        <div class="section-distance-new"/>
        <Steps :headline="$t('fiction.steps.message')" :message="$t('fiction.steps.headline')" :steps="steps"
               disable-steps
               switch-headline
               width="uk-child-width-expand@l uk-child-width-1-2@s uk-child-width-1-1"/>

        <div class="section-distance-new"/>
        <SiteHeadline :headline="$t('fiction.references.message')" :message="$t('references')"
                      switch-headline/>
        <div class="headline-message-distance"/>
        <ul class="js-filter uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-flex uk-flex-center" uk-grid>
            <VideoHeadlineItem v-for="(product, key) in references" :id="product.id+''" :key="key"
                               :data-category="product.category"
                               :headline="$t(product.category)"
                               :isCampaign="product.video_link === ''"
                               :itemKey="key"
                               :logo="product.logo ?getBaseUrl+'/api/storage/app/'+product.logo : ''"
                               :message="product.name"
                               :route="product.video_link === '' ? '/'+$t('references').toLowerCase()+'/'+product.shortname : ''"
                               :shortname="product.shortname"
                               :video="product.video_link"
                               :videoPreview="getBaseUrl+'/api/storage/app/'+product.preview_video"
                               class="item-struct"
                               is-max
            />
        </ul>

        <div class="section-distance-new"/>
        <AwardList :awards="awards" :headline="$t('awards.message')" :message="$t('awards')"
                   switch-headline/>


        <div v-if="false" class="section-distance-new"/>
        <NumberReferences v-if="false"/>

        <div class="last-button-distance"/>
        <LastHeadlineButton :buttonMessage="$t('contact')" :headline="$t('createYourOwnStoryTogether')"
                            :route="'contact'"
                            buttonClass="success"/>

        <div class="last-button-distance"/>

    </div>
</template>


<script>

import SiteHeadline from "../../components/SiteHeadline.vue";
import Seo from "../../components/Seo.vue";
import VideoHeadlineItem from "../../components/VideoHeadlineItem.vue";
import HighlightVideoText from "../../components/HighlightVideoText.vue";
import Steps from "../../components/Steps.vue";
import NumberReferences from "../../components/NumberReferences.vue";
import axios from "axios";
import LastHeadlineButton from "@/components/LastHeadlineButton.vue";
import AwardList from "@/components/AwardList.vue";

export default {
    name: 'Fiction',
    components: {
        AwardList,
        LastHeadlineButton,
        NumberReferences,
        Steps,
        HighlightVideoText,
        VideoHeadlineItem,
        Seo,
        SiteHeadline
    },
    data() {
        return {
            serviceItems: [
                {
                    headline: "fiction.message",
                    title: "",
                    message: "fiction.text",
                    video: "fiction.mp4"
                },
            ],

            references: [],

            awards: [
                "cannes.png",
                "deutscherkamerapreis.png",
                "jupiter.png",
                "lafa.png",
                "selb.png",
                "shokingshorts.png",
            ],

            steps: [
                {
                    title: "conception",
                    message: "conception.message",
                    icon: "pencil",
                    translate: true
                },

                {
                    title: "preproduction",
                    message: "preproduction.message",
                    icon: "list",
                    translate: true
                },
                {
                    title: "spin",
                    message: "spin.message",
                    icon: "video-camera",
                    translate: true
                },
                {
                    title: "postproduction",
                    message: "postproduction.message",
                    icon: "image",
                    translate: true
                },
            ],
        };
    },
    created() {
        this.getReferences();

        let hash = this.$route.hash;
        if (hash) {
            this.filter = hash.substring(1);
        }
    },
    methods: {
        getReferences() {
            let http = axios.create();

            http.get(this.getBaseUrl + '/api/references?category=fiction').then(response => {
                this.references = response.data;
                this.loading = false;

            }).catch(() => {

            });
        },
    },
}
</script>


<style lang="scss" scoped>

.white {
    margin-bottom: 20px
}

.strong-message {
    font-size: 20px !important;
}

</style>
